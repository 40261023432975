import React from "react"
import Layout from "../components/Layout"
import Grid from "../components/Grid"
import css from "./api.module.scss"

const metadata = {
  title: "API documentation | ReferralCandy",
}

export default (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <Grid className={css.container} centeredClassName={css.centered}>
        <div className={css.content}>
          <h1>ReferralCandy API</h1>
          <h2 id="introduction">Introduction</h2>
          <hr />
          <p>
            The ReferralCandy API provides a simple interface with{" "}
            <a
              href="http://en.wikipedia.org/wiki/JSON"
              target="_blank"
              rel="noopener noreferrer"
            >
              JSON
            </a>
            -formatted responses to integrate your store with the ReferralCandy
            referral platform.
          </p>
          <p>
            ReferralCandy API calls are made to the following URL:
            <pre>
              {`https://my.referralcandy.com/api/v1/`}
              <b>
                <i>some-method</i>
              </b>
              {`.json`}
            </pre>
          </p>
          <p>(Please note that all API input should be encoded in UTF-8.)</p>
          <h2 id="authentication">Authentication</h2>
          <hr />
          <p>
            Each call to the ReferralCandy API requires the sending of the
            current{" "}
            <a
              href="http://en.wikipedia.org/wiki/Unix_time"
              target="_blank"
              rel="noopener noreferrer"
            >
              UNIX timestamp
            </a>
            , Access ID and signature parameters. Your API Access ID can be
            found on your account settings page. The signature parameter is
            generated from your API Secret Key which can also be found on your
            account settings page.
          </p>
          <p>
            To generate the signature for a particular call to the API, carry
            out the following steps:
          </p>
          <ol>
            <li>
              Gather the API call parameters into an array of strings of the
              format
              <i>"parameter_name=parameter_value".</i>
            </li>
            <li>Sort the name/value pairs in the array.</li>
            <li>Join the array elements into a string.</li>
            <li>
              Prepend the API Secret Key to the string to form a combined
              string.
            </li>
            <li>The signature is the MD5 of this combined string.</li>
          </ol>
          <p>A sample signature calculation is as follows:</p>
          <ol>
            <li>
              <pre>{`["timestamp=1296664909", "accessID=acihijaklscj", "name=john"]`}</pre>
            </li>
            <li>
              <pre>{`["accessID=acihijaklscj", "name=john", "timestamp=1296664909"]`}</pre>
            </li>
            <li>
              <pre>{`"accessID=acihijaklscjname=johntimestamp=1296664909"`}</pre>
            </li>
            <li>
              <pre>{`"APISECRETKEYaccessID=acihijaklscjname=johntimestamp=1296664909"`}</pre>
            </li>
            <li>
              <pre>{`signature = MD5("APISECRETKEYaccessID=acihijaklscjname=johntimestamp=1296664909") = "04535fe455e9f77a90c06e81d7888569"`}</pre>
            </li>
          </ol>
          <h2>Response Codes</h2>
          <hr />
          <p>
            The ReferralCandy API uses standard HTTP status codes to indicate
            success or failure of API calls.
          </p>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>HTTP Code</th>
                  <th>Meaning</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>200</td>
                  <td>API call was successful.</td>
                </tr>
                <tr>
                  <td>400</td>
                  <td>
                    You've made an error in your request. See the message field
                    in the response for details.
                  </td>
                </tr>
                <tr>
                  <td>401</td>
                  <td>Authentication credentials provided were incorrect.</td>
                </tr>
                <tr>
                  <td>404</td>
                  <td>Unknown API method.</td>
                </tr>
                <tr>
                  <td>500</td>
                  <td>A temporary internal server error.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 id="api-methods">API Methods</h2>
          <hr />
          <h2 id="verify">Verify Method</h2>
          <h3>Overview</h3>
          <p>
            This method lets you verify that authentication for your API calls
            is set up correctly.
          </p>
          <h3>URL</h3>
          <pre>{`https://my.referralcandy.com/api/v1/verify.json`}</pre>
          <h3>Request Method</h3>
          <pre>{`GET`}</pre>
          <h3>Input Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Required</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>timestamp</td>
                  <td>UNIX timestamp of API call.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>accessID</td>
                  <td>API Access ID.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>signature</td>
                  <td>Calculated signature.</td>
                  <td>yes</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Output Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>message</td>
                  <td>A message indicating the outcome of the API call.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Sample Result</h3>
          <pre>{`{"message":"Verification Ok"}`}</pre>
          <h2 id="purchase">Purchase Method</h2>
          <h3>Overview</h3>
          <p>
            This method lets you register a new purchase at your store. A
            referral email will be sent to the customer.
          </p>
          <h3>URL</h3>
          <pre>{`https://my.referralcandy.com/api/v1/purchase.json`}</pre>
          <h3>Request Method</h3>
          <pre>{`POST`}</pre>
          <h3>Input Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Required</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>first_name</td>
                  <td>Customer's first name.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>last_name</td>
                  <td>Customer's last name.</td>
                  <td>no</td>
                </tr>
                <tr>
                  <td>email</td>
                  <td>Customer's email address.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>locale</td>
                  <td>
                    Customer's preferred language. Defaults to campaign's
                    default language if it is not set or not available to the
                    campaign. This is a complete list of valid values (ISO 639-1
                    language code): en, fr, de, es, it, ja, nl, ru, zh-CN,
                    zh-HK, zh-TW, da, no, sv, pt-BR.
                  </td>
                  <td>no</td>
                </tr>
                <tr>
                  <td>discount_code</td>
                  <td>
                    Discount code used in the order. Blank if no discount code
                    was used.
                  </td>
                  <td>yes if discount code was used</td>
                </tr>
                <tr>
                  <td>accepts_marketing</td>
                  <td>
                    Whether the customer opted in to marketing (true/false).
                    Defaults to true.
                  </td>
                  <td>no</td>
                </tr>
                <tr>
                  <td>order_timestamp</td>
                  <td>UNIX timestamp of order.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>browser_ip</td>
                  <td>IP address of customer when making the purchase.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>user_agent</td>
                  <td>User agent string of the customer's web browser.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>invoice_amount</td>
                  <td>Total invoice amount for this purchase.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>currency_code</td>
                  <td>
                    ISO 4217 currency code used in order invoice (e.g. USD, GBP,
                    INR).
                  </td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>external_reference_id</td>
                  <td>
                    An ID that can be used to track this purchase externally.
                  </td>
                  <td>no</td>
                </tr>
                <tr>
                  <td>timestamp</td>
                  <td>UNIX timestamp of API call.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>accessID</td>
                  <td>API Access ID.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>signature</td>
                  <td>Calculated signature.</td>
                  <td>yes</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Output Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>message</td>
                  <td>A message indicating the outcome of the API call.</td>
                </tr>
                <tr>
                  <td>referralcorner_url</td>
                  <td>URL of the new customer's Portal Sharing Page.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Sample Result</h3>
          <pre>{`{"message":"Success","referralcorner_url":"http://refcandy.referralcandy.com/BW299X"}`}</pre>
          <h2 id="referrals">Referrals Method</h2>
          <h3>Overview</h3>
          <p>
            This method lets you query for referred purchases made over some
            period of time.
          </p>
          <h3>URL</h3>
          <pre>{`https://my.referralcandy.com/api/v1/referrals.json`}</pre>
          <h3>Request Method</h3>
          <pre>{`GET`}</pre>
          <h3>Input Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Required</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>period_from</td>
                  <td>UNIX timestamp of start of query period.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>period_to</td>
                  <td>
                    UNIX timestamp of end of query period. Defaults to most
                    recent timestamp.
                  </td>
                  <td>no</td>
                </tr>
                <tr>
                  <td>customer_email</td>
                  <td>
                    Advocate's email address. Return referred purchases made by
                    advocate if set. Return all referred purchases if not set.
                  </td>
                  <td>no</td>
                </tr>
                <tr>
                  <td>pending_review_only</td>
                  <td>
                    Whether to only return referred purchases that are pending
                    the review period (true/false). Defaults to false - this
                    returns referred purchases regardless of review period
                    status.
                  </td>
                  <td>no</td>
                </tr>
                <tr>
                  <td>timestamp</td>
                  <td>UNIX timestamp of API call.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>accessID</td>
                  <td>API Access ID.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>signature</td>
                  <td>Calculated signature.</td>
                  <td>yes</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Output Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>referrals</td>
                  <td>
                    Array of referred customers and referral timestamps.
                    <br />
                    <br />
                    <div className={css.table}>
                      <table>
                        <thead>
                          <tr>
                            <th>Field</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>referral_email</td>
                            <td>Email address of referred customer.</td>
                          </tr>
                          <tr>
                            <td>referral_timestamp</td>
                            <td>
                              UNIX timestamp when referral purchase was made.
                            </td>
                          </tr>
                          <tr>
                            <td>referring_email</td>
                            <td>
                              Email address of advocate who made the referral.
                            </td>
                          </tr>
                          <tr>
                            <td>review_period_over</td>
                            <td>
                              Whether the review period for this referred
                              purchase is over.
                            </td>
                          </tr>
                          <tr>
                            <td>external_reference_id</td>
                            <td>
                              An ID that can be used to track this referred
                              purchase externally.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>period_from</td>
                  <td>UNIX timestamp of start of query period.</td>
                </tr>
                <tr>
                  <td>period_to</td>
                  <td>
                    UNIX timestamp of end of query period of the results
                    returned. This might not be the same as the period_to value
                    requested since successful referrals can only be detected up
                    till a couple of minutes before the current time.
                  </td>
                </tr>
                <tr>
                  <td>message</td>
                  <td>A message indicating the outcome of the API call.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Sample Result</h3>
          <pre>{`{"message":"Success","referrals":[{"referral_email":"referral1@example.com","referral_timestamp":1329738979,"referring_email":"customer@example.com"},{"referral_email":"referral2@example.com","referral_timestamp":1329738984,"referring_email":"customer@example.com"}],"period_from":1329738679,"period_to":1329739284}`}</pre>
          <h2 id="referral">Referral Method</h2>
          <h3>Overview</h3>
          <p>
            This method lets you update the purchase status of a referred
            customer. By default, an email is sent out to the customer who made
            the referral to inform him/her that he/she is not eligible for a
            recent referral reward.
          </p>
          Example:
          <br />
          Bob makes a purchase at your store, referred by Amy. Bob returns his
          purchase. Make an 'Update Referral' API call, and set 'returned' to
          true. By default, Amy gets an email informing her that she is no
          longer eligible for the referral reward.
          <br />
          <h3>URL</h3>
          <pre>{`https://my.referralcandy.com/api/v1/referral.json`}</pre>
          <h3>Request Method</h3>
          <pre>{`POST`}</pre>
          <h3>Input Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Required</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>customer_email</td>
                  <td>
                    Email address of customer who made the referred purchase.
                  </td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>notify</td>
                  <td>
                    Whether to notify customer who referred customer specified
                    above that the referral has been disregarded (true/false).
                    Defaults to true.
                  </td>
                  <td>no</td>
                </tr>
                <tr>
                  <td>returned</td>
                  <td>
                    Whether customer has returned his/her purchase (true/false).
                    If 'returned' is set to true, the referral will be
                    disregarded and rewards will not be paid out.
                  </td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>timestamp</td>
                  <td>UNIX timestamp of API call.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>accessID</td>
                  <td>API Access ID.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>signature</td>
                  <td>Calculated signature.</td>
                  <td>yes</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Output Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>message</td>
                  <td>A message indicating the outcome of the API call.</td>
                </tr>
                <tr>
                  <td>customer_email</td>
                  <td>Customer email address.</td>
                </tr>
                <tr>
                  <td>returned</td>
                  <td>Whether customer has returned his/her purchase.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Sample Result</h3>
          <pre>{`{"message":"Success","customer_email":"customer@example.com","returned":true}`}</pre>
          <h2 id="referrer">Referrer Method</h2>
          <h3>Overview</h3>
          <p>
            This method lets you query for the referrer of a particular
            customer.
          </p>
          <h3>URL</h3>
          <pre>{`https://my.referralcandy.com/api/v1/referrer.json`}</pre>
          <h3>Request Method</h3>
          <pre>{`GET`}</pre>
          <h3>Input Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Required</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>customer_email</td>
                  <td>Customer email address.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>timestamp</td>
                  <td>UNIX timestamp of API call.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>accessID</td>
                  <td>API Access ID.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>signature</td>
                  <td>Calculated signature.</td>
                  <td>yes</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Output Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>referrer</td>
                  <td>
                    Email address of referring customer if one exists, null
                    otherwise.
                  </td>
                </tr>
                <tr>
                  <td>message</td>
                  <td>A message indicating the outcome of the API call.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Sample Result</h3>
          <pre>{`{"message":"Success","referrer":"referrer@example.com"}`}</pre>
          <h2 id="contacts">Contacts Method</h2>
          <h3>Overview</h3>
          <p>
            This method lets you query for contacts involved in your referral
            campaign.
          </p>
          Example:
          <br />
          Query with id = 50 and limit = 100 will return the 100 contacts
          invited to your referral campaign after (and including) contact with
          id = 50.
          <br />
          <h3>URL</h3>
          <pre>{`https://my.referralcandy.com/api/v1/contacts.json`}</pre>
          <h3>Request Method</h3>
          <pre>{`GET`}</pre>
          <h3>Input Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Required</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>id</td>
                  <td>
                    Lowest id of contact returned by query. Defaults to 1.
                  </td>
                  <td>no</td>
                </tr>
                <tr>
                  <td>limit</td>
                  <td>
                    Number of contacts to return. Defaults to 100. Capped at
                    100.
                  </td>
                  <td>no</td>
                </tr>
                <tr>
                  <td>timestamp</td>
                  <td>UNIX timestamp of API call.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>accessID</td>
                  <td>API Access ID.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>signature</td>
                  <td>Calculated signature.</td>
                  <td>yes</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Output Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>contacts</td>
                  <td>
                    Array of contacts.
                    <br />
                    <br />
                    <div className={css.table}>
                      <table>
                        <thead>
                          <tr>
                            <th>Field</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>id</td>
                            <td>Unique identifier of contact.</td>
                          </tr>
                          <tr>
                            <td>first_name</td>
                            <td>First name of contact.</td>
                          </tr>
                          <tr>
                            <td>last_name</td>
                            <td>Last name of contact.</td>
                          </tr>
                          <tr>
                            <td>email</td>
                            <td>Email address of contact.</td>
                          </tr>
                          <tr>
                            <td>purchase_made</td>
                            <td>
                              Whether contact made a purchase at the store
                              (true/false).
                            </td>
                          </tr>
                          <tr>
                            <td>purchases</td>
                            <td>
                              Array of purchases made by contact.
                              <br />
                              <div className={css.table}>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Field</th>
                                      <th>Description</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>purchased_at</td>
                                      <td>UNIX timestamp of purchase.</td>
                                    </tr>
                                    <tr>
                                      <td>amount</td>
                                      <td>Invoice amount of purchase.</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>unsubscribed</td>
                            <td>
                              Whether contact is unsubscribed (true/false).
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>total_count</td>
                  <td>
                    Total number of contacts involved in your referral campaign
                    to date.
                  </td>
                </tr>
                <tr>
                  <td>message</td>
                  <td>A message indicating the outcome of the API call.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Sample Result</h3>
          <pre>{`{"message":"Success","total_count":400,"contacts":[{"id":800,"first_name":"Contact","last_name":"One","email":"contact1@example.com","purchase_made":true,"purchases":[{"purchased_at":1329738679,"amount":97.0}],"unsubscribed":false},{"id":1250,"first_name":"Contact","last_name":"Two","email":"contact2@example.com","purchase_made":false,"purchases":[],"unsubscribed":false}]}`}</pre>
          <h2 id="signup">Signup Method</h2>
          <h3>Overview</h3>
          <p>
            This method lets you sign an advocate up for your referral program.
            If the advocate already exsits, you can use this method to retrieve
            the advocate's referral link and Portal Sharing Page address.
          </p>
          <h3>URL</h3>
          <pre>{`https://my.referralcandy.com/api/v1/signup.json`}</pre>
          <h3>Request Method</h3>
          <pre>{`POST`}</pre>
          <h3>Input Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Required</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>first_name</td>
                  <td>Advocate's first name.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>last_name</td>
                  <td>Advocate's last name.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>email</td>
                  <td>Advocate's email address.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>timestamp</td>
                  <td>UNIX timestamp of API call.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>accessID</td>
                  <td>API Access ID.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>signature</td>
                  <td>Calculated signature.</td>
                  <td>yes</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Output Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>message</td>
                  <td>A message indicating the outcome of the API call.</td>
                </tr>
                <tr>
                  <td>referralcorner_url</td>
                  <td>URL of the advocate's Portal Sharing Page.</td>
                </tr>
                <tr>
                  <td>referral_link</td>
                  <td>URL of the advocate's unique referral link.</td>
                </tr>
                <tr>
                  <td>invite_code</td>
                  <td>The latest invite code(AIC) of the advocate.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Sample Result</h3>
          <pre>{`{"message":"Success","referralcorner_url":"http://refcandy.referralcandy.com/BW299X","referral_link":"http://refcandy.refr.cc/ABCDEF","invite_code":"BW299X"}`}</pre>
          <h2 id="invite">Invite Method</h2>
          <h3>Overview</h3>
          <p>
            This method lets you send out an in-app invite to a contact. This
            counts against your in-app invite quota.
          </p>
          <h3>URL</h3>
          <pre>{`https://my.referralcandy.com/api/v1/invite.json`}</pre>
          <h3>Request Method</h3>
          <pre>{`POST`}</pre>
          <h3>Input Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Required</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>email</td>
                  <td>Contact's email address.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>timestamp</td>
                  <td>UNIX timestamp of API call.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>accessID</td>
                  <td>API Access ID.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>signature</td>
                  <td>Calculated signature.</td>
                  <td>yes</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Output Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>message</td>
                  <td>A message indicating the outcome of the API call.</td>
                </tr>
                <tr>
                  <td>invite</td>
                  <td>
                    The number of in-app invites remaining for your campaign.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Sample Result</h3>
          <pre>{`{"message":"Success","invites":500}`}</pre>
          <h2 id="unsubscribed">Unsubscribed Method</h2>
          <h3>Overview</h3>
          <p>
            This method lets you unsubscribe and resubscribe a contact in your
            referral program.
          </p>
          <h3>URL</h3>
          <pre>{`https://my.referralcandy.com/api/v1/unsubscribed.json`}</pre>
          <h3>Request Method</h3>
          <pre>{`PUT`}</pre>
          <h3>Input Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Required</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>email</td>
                  <td>Contact's email address.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>unsubscribed</td>
                  <td>
                    Whether to unsubscribe or resubscribe contact (true/false).
                  </td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>timestamp</td>
                  <td>UNIX timestamp of API call.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>accessID</td>
                  <td>API Access ID.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>signature</td>
                  <td>Calculated signature.</td>
                  <td>yes</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Output Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>message</td>
                  <td>A message indicating the outcome of the API call.</td>
                </tr>
                <tr>
                  <td>email</td>
                  <td>Customer email address.</td>
                </tr>
                <tr>
                  <td>unsubscribed</td>
                  <td>Whether customer is unsubscribed.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Sample Result</h3>
          <pre>{`{"message":"Success","email":"contact1@example.com","unsubscribed":true}`}</pre>
          <h2 id="get-rewards">Get Rewards Method</h2>
          <h3>Overview</h3>
          <p>
            This method lets you query for rewards which match some criteria.
          </p>
          <h3>URL</h3>
          <pre>{`https://my.referralcandy.com/api/v1/rewards.json`}</pre>
          <h3>Request Method</h3>
          <pre>{`GET`}</pre>
          <h3>Input Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Required</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>email</td>
                  <td>
                    When given, the result only contains rewards from the
                    advocate with this email address.
                  </td>
                  <td>no</td>
                </tr>
                <tr>
                  <td>since_id</td>
                  <td>
                    When given, the result starts from the next matching reward
                    earned after the specified reward ID. Otherwise, the result
                    starts with the first matching reward.
                  </td>
                  <td>no</td>
                </tr>
                <tr>
                  <td>status</td>
                  <td>
                    When specified, the result contains rewards with the
                    matching status. Otherwise, the result contains rewards
                    matching all statuses. Accepted values are
                    pending_fulfillment, delivered.
                  </td>
                  <td>no</td>
                </tr>
                <tr>
                  <td>type</td>
                  <td>
                    When specified, the result contains rewards with the
                    matching type. Otherwise, the result contains rewards
                    matching all types. Accepted values are cash, coupon,
                    custom, none.
                  </td>
                  <td>no</td>
                </tr>
                <tr>
                  <td>timestamp</td>
                  <td>UNIX timestamp of API call.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>accessID</td>
                  <td>API Access ID.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>signature</td>
                  <td>Calculated signature.</td>
                  <td>yes</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Output Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>message</td>
                  <td>A message indicating the outcome of the API call.</td>
                </tr>
                <tr>
                  <td>rewards</td>
                  <td>The first 200 matching rewards.</td>
                </tr>
                <tr>
                  <td>since_id</td>
                  <td>The value specified in the request if available.</td>
                </tr>
                <tr>
                  <td>status</td>
                  <td>The value specified in the request if available.</td>
                </tr>
                <tr>
                  <td>type</td>
                  <td>The value specified in the request if available.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Sample Result</h3>
          <pre>{`{:message=>"Success", :rewards=>[{:advocate_email=>"john@example.com", :advocate_name=>"John Smith", :created_at=>1442584289, :description=>"20% discount", :id=>2, :status=>"delivered", :type=>"custom"}, {:advocate_email=>"alice@example.com", :advocate_name=>"Alice Bob", :created_at=>1442584426, :description=>"20% discount", :id=>3, :status=>"pending_fulfilment", :type=>"custom"}], :since_id=>1}`}</pre>
          <h2 id="reward-purchases">Reward Purchases Method</h2>
          <h3>Overview</h3>
          <p>
            This method lets you query for the referred purchases that are
            associated with a reward.
          </p>
          <h3>URL</h3>
          <pre>{`https://my.referralcandy.com/api/v1/reward_purchases.json`}</pre>
          <h3>Request Method</h3>
          <pre>{`GET`}</pre>
          <h3>Input Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Required</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>reward_id</td>
                  <td>The ID of the reward.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>timestamp</td>
                  <td>UNIX timestamp of API call.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>accessID</td>
                  <td>API Access ID.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>signature</td>
                  <td>Calculated signature.</td>
                  <td>yes</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Output Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>message</td>
                  <td>A message indicating the outcome of the API call.</td>
                </tr>
                <tr>
                  <td>referred_purchases</td>
                  <td>
                    The list of the referred purchases that is associated with
                    the reward.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Sample Result</h3>
          <pre>{`{"message":"Success", "referred_purchases":[{"id":1, "external_reference_id":"ORDER_1", "purchaser_email":"john@example.com"},{"id":2, "external_reference_id":"ORDER_2", "purchaser_email":"alice@example.com"}]}`}</pre>
          <h2 id="post-rewards">Post Rewards Method</h2>
          <h3>Overview</h3>
          <p>This method lets you update a custom reward.</p>
          <h3>URL</h3>
          <pre>{`https://my.referralcandy.com/api/v1/rewards.json`}</pre>
          <h3>Request Method</h3>
          <pre>{`POST`}</pre>
          <h3>Input Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Required</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>id</td>
                  <td>The ID of the reward.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>status</td>
                  <td>
                    The status of the reward. Accepted values are
                    pending_fulfillment, delivered.
                  </td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>timestamp</td>
                  <td>UNIX timestamp of API call.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>accessID</td>
                  <td>API Access ID.</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>signature</td>
                  <td>Calculated signature.</td>
                  <td>yes</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Output Parameters</h3>
          <div className={css.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Field</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>message</td>
                  <td>
                    A message indicating the outcome of the API call if
                    available.
                  </td>
                </tr>
                <tr>
                  <td>messages</td>
                  <td>
                    A list of messages indicating the outcome of the API call if
                    available.
                  </td>
                </tr>
                <tr>
                  <td>reward</td>
                  <td>The updated reward.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3>Sample Result</h3>
          <pre>{`{:message=>"Success", :reward=>{:advocate_email=>"john@example.com", :advocate_name=>"John Smith", :created_at=>1442584289, :description=>"20% discount", :id=>3938282, :status=>"delivered", :type=>"custom"}}`}</pre>
          <h2 id="troubleshooting">Troubleshooting</h2>
          <hr />
          <h3>Signature Calculation</h3>
          <p>
            A common mistake is that the same URI encoded API params for making
            a POST request are also used to compute the signature.
          </p>
          <p>For example, if you had the following params:</p>
          <pre>{`{email: "abc@xyz.com", name: "john & jane"}`}</pre>
          <p>Your POST body would be URI encoded:</p>
          <pre>{`email=abc%40xyz.com&name=john+%26+jane`}</pre>
          <p>
            However, your MD5 should be computed with the literal string (not
            the URI encoded string):
          </p>
          <pre>{`MD5("...email=abc@xyz.comname=john & jane...")`}</pre>
        </div>
        <div>
          <div className={css.sidebar}>
            <ul>
              <li>
                <a href="/api#introduction">Introduction</a>
              </li>
              <li>
                <a href="/api#authentication">Authentication</a>
              </li>
              <li>
                <a href="/api#response-codes">Response Codes</a>
              </li>
              <li>
                <a href="/api#api-methods">API Methods</a>
                <ul>
                  <li>
                    <a href="/api#verify">Verify</a>
                  </li>
                  <li>
                    <a href="/api#purchase">Purchase</a>
                  </li>
                  <li>
                    <a href="/api#referrals">Referrals</a>
                  </li>
                  <li>
                    <a href="/api#referral">Referral</a>
                  </li>
                  <li>
                    <a href="/api#referrer">Referrer</a>
                  </li>
                  <li>
                    <a href="/api#contacts">Contacts</a>
                  </li>
                  <li>
                    <a href="/api#signup">Signup</a>
                  </li>
                  <li>
                    <a href="/api#invite">Invite</a>
                  </li>
                  <li>
                    <a href="/api#unsubscribed">Unsubscribed</a>
                  </li>
                  <li>
                    <a href="/api#get-rewards">Get Rewards</a>
                  </li>
                  <li>
                    <a href="/api#reward-purchases">Reward Purchases</a>
                  </li>
                  <li>
                    <a href="/api#post-rewards">Post Rewards</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="https://github.com/ReferralCandy/referral_candy">
                  API Clients
                </a>
                <ul>
                  <li>
                    <a href="https://github.com/ReferralCandy/referral_candy">
                      Ruby
                    </a>
                  </li>
                  <li>
                    <a href="https://github.com/ReferralCandy/referral_candy_python">
                      Python
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/api#troubleshooting">Troubleshooting</a>
              </li>
            </ul>
          </div>
        </div>
      </Grid>
    </Layout>
  )
}
